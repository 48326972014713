import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeroImage = () => {
  return (
    <div
      className="-mt-8 overflow-hidden md:-mt-12"
      style={{
        marginRight: `calc(50% - 50vw)`,
        marginLeft: `calc(50% - 50vw)`,
        maxHeight: `700px`,
      }}
    >
      <div className="hidden md:block">
        <StaticImage
          src="../../images/do-cafe/cafe-inside.jpg"
          alt="cafe inside"
        />
      </div>
      <div className="md:hidden">
        <StaticImage
          src="../../images/do-cafe/cafe-inside-sp.png"
          alt="cafe inside"
          layout="fullWidth"
        />
      </div>
    </div>
  )
}

export default HeroImage
