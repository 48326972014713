import React from "react"
import HeadingM from "../shared/HeadingM"
import InstagramBase from "../shared/top/Instagram"

const Instagram = () => (
  <div id="instagram" className="mt-20 md:mt-24">
    <HeadingM title="インスタグラム" target="doCafe" />
    <InstagramBase accountName="docafe0501" />
  </div>
)

export default Instagram
