import React from "react"

const Concept = () => {
  return (
    <div id="about" className="mt-12 text-center font-kiwiMaru">
      <div
        className={`md:p-8 mb-10 text-lg leading-relaxed text-center bg-repeat-round md:text-xl md:leading-relaxed`}
      >
        子どもたちが大人になるにつれて働く場の居場所づくりが必要になります。
        <br />
        <br />
        Do CAFEは、併設する放課後等デイサービスとの協力体制を持ちながら
        <br className="hidden md:block" />
        将来的に就労支援を目指し、子どもたちの就労体験の場を目指します。
      </div>
    </div>
  )
}

export default Concept
