import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import GalleryBase from "../shared/about/Gallery"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  {
    allContentfulAsset(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "doCafePhoto" } } }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData
        }
      }
    }
  }
`

const Gallery = () => {
  const data = useStaticQuery(query)
  const items = data.allContentfulAsset.edges.map(item => {
    const { gatsbyImageData } = item.node
    return {
      src: gatsbyImageData.images.fallback.src,
      height: gatsbyImageData.height,
      width: gatsbyImageData.width,
    }
  })
  return (
    <div className="mt-12 md:mt-28">
      <HeadingM title="ギャラリー" target="doCafe" />
      <GalleryBase items={items} />
    </div>
  )
}

export default Gallery
