import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import StaffBase from "../shared/about/Staff"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  {
    allContentfulDoCafeStaff(sort: { fields: order, order: ASC }) {
      nodes {
        name
        comment {
          comment
        }
        image {
          gatsbyImageData
        }
      }
    }
  }
`

const Staff = () => {
  const data = useStaticQuery(query)
  const items = data.allContentfulDoCafeStaff.nodes.map(item => ({
    image: getImage(item.image),
    name: item.name,
    comment: item.comment.comment,
  }))
  return (
    <div>
      <HeadingM title="スタッフ紹介" target="doCafe" />
      <StaffBase items={items} target="doCafe" />
    </div>
  )
}

export default Staff
