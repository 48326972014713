import React, { VFC } from "react"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
  accountName: string
}

const Instagram: VFC<Props> = ({ accountName }) => {
  return (
    <div className="mx-auto mt-12 ">
      <div className="flex items-center justify-center gap-3 text-lg font-kiwiMaru">
        <a href={`https://www.instagram.com/${accountName}/`} target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" color="dimgray" />
        </a>
        インスタグラムも更新中！
      </div>
      <a
        className="block mx-auto text-center underline w-fit mt-7 hover:no-underline"
        href={`https://www.instagram.com/${accountName}/`}
        target="_blank"
      >
        クリックしてみにいく
        <FontAwesomeIcon icon={faUpRightFromSquare} color="" className="ml-2" />
      </a>
    </div>
  )
}

export default Instagram
