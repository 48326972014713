import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../../components/do-cafe/Layout"
import SEO from "../../components/Seo"
import Concept from "../../components/do-cafe/Concept"
import About from "../../components/do-cafe/About"
import Menu from "../../components/do-cafe/Menu"
import Detail from "../../components/do-cafe/Detail"
import Instagram from "../../components/do-cafe/Instagram"
import HeroImage from "../../components/do-cafe/HeroImage"
import OpeningShutter from "../../components/shared/top/OpeningShutter"
import Partners from "../../components/do-cafe/Partners"
import Gallery from "../../components/do-cafe/Gallery"
import Staff from "../../components/do-cafe/Staff"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "do-cafe/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <SEO siteTitle="Do CAFE" />
      <OpeningShutter logo={getImage(data.logo)} target="doCafe" />
      <div className="-mt-28">
        <HeroImage />
      </div>
      <Concept />
      <About />
      <Menu />
      <Detail />
      <Staff />
      <Gallery />
      <Instagram />
      <Partners />
    </Layout>
  )
}

export default IndexPage
