import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

type Props = {
  items: {
    logo: IGatsbyImageData
    link: string
    isSelf?: boolean
  }[]
}

const Partners: React.VFC<Props> = ({ items }) => {
  return (
    <div
      className={`${
        items.length === 1 ? "flex" : "grid"
      } items-center justify-center grid-cols-2 gap-10 mt-12 text-center md:space-x-8 md:flex md:mt-16`}
    >
      {items.map(item => {
        return (
          <a
            key={`partner-${item.link}`}
            href={item.link}
            target={item.isSelf ? "_self" : "_blank"}
          >
            <GatsbyImage image={item.logo} alt="logo" />
          </a>
        )
      })}
    </div>
  )
}

export default Partners
