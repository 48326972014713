import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import DrawerMenu from "../top/DrawerMenu"
import useHeaderScroll from "../../hooks/ useHeaderScroll"

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  const { isScrolled } = useHeaderScroll(700)
  const toggleMenu = () => {
    toggleExpansion(!isExpanded)
  }

  const menuList = [
    { text: "Do CAFEについて", path: "/do-cafe/#about" },
    { text: "メニュー", path: "/do-cafe/#menu" },
    { text: "店舗詳細", path: "/do-cafe/#info" },
    { text: "インスタグラム", path: "/do-cafe/#instagram" },
    { text: "企業TOP", path: "/" },
  ]

  return (
    <nav
      className={`sticky top-0 z-10 flex flex-wrap items-center justify-between w-full px-5 py-3 ${
        isScrolled ? "bg-transparent md:bg-opacity-70 md:bg-white" : ""
      }`}
    >
      <StaticImage
        src="../../images/do-cafe/logo.png"
        alt="Do CAFE logo"
        layout="fixed"
        width={50}
      />
      <div className="hidden space-x-7 md:flex">
        {menuList.map(item => {
          return (
            <Link
              key={item.path}
              to={item.path}
              className="text-lg font-bold font-kiwiMaru"
            >
              {item.text}
            </Link>
          )
        })}
      </div>
      <button
        className="z-20 hamburger md:hidden"
        id="hamburger1"
        onClick={toggleMenu}
      >
        <span className={`line ${isExpanded ? "line1-active" : ""}`}></span>
        <span className={`line ${isExpanded ? "line2-active" : ""}`}></span>
        <span className={`line ${isExpanded ? "line3-active" : ""}`}></span>
      </button>
      <Transition
        show={isExpanded}
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="fixed top-0 z-10 w-3/4 h-screen px-12 py-6 bg-white -right-5"
      >
        <DrawerMenu toggleMenu={toggleMenu} items={menuList} />
      </Transition>
    </nav>
  )
}

export default Header
