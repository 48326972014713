import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const About = () => {
  return (
    <div
      style={{
        margin: `4rem calc(50% - 50vw) 0`,
      }}
    >
      <div
        className={`h-4 bg-repeat-x md:h-8 bg-doCafeWaveSp md:bg-doCafeWave`}
      ></div>
      <div className="bg-repeat-round bg-doCafeImage">
        <div className="max-w-4xl px-4 mx-auto">
          <div className="py-20 text-center">
            <div>
              <p className="text-2xl md:text-3xl font-kiwiMaru">Do CAFEの特徴</p>
              <div className="mt-10 leading-relaxed">
                地域とのつながりを大切にし、地元の食材を厳選して提供しています。
                <br className="hidden md:block" />
                カフェ内は落ち着いた雰囲気でイートインスペースも完備しています。
                <br />
                <br />
                子どもから大人まで安心して過ごせる、そんな暖かい雰囲気が漂う居心地の良い空間も魅力です。
                <br className="hidden md:block" />
                テイクアウトのお客様も大歓迎です。
                <br />
                ※無料コンセントあります。
              </div>
            </div>
            <div className="m-auto mt-8 h-fit">
              <StaticImage
                src="../../images/do-cafe/coffee.png"
                alt="coffee"
                width={240}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`h-4 rotate-180 bg-repeat-x md:h-8 bg-doCafeWaveSp md:bg-doCafeWave`}
      ></div>
    </div>
  )
}

export default About
